import { useContext } from "react"

import { VerticalSigningStateContext, VerticalSigningRoadwayContext } from "./VerticalSigningContext.context"

export const useVerticalSigningStateContext = () => {
    const context = useContext(VerticalSigningStateContext)
    if (context === undefined) {
        throw new Error("useVerticalSigningStateContext must be used within an VerticalSigningStateContext")
    }
    return context
}

export const useVerticalSigningDispatchContext = () => {
    const context = useContext(VerticalSigningRoadwayContext)
    if (context === undefined) {
        throw new Error("useVerticalSigningDispatchContext must be used within an VerticalSigningRoadwayContext")
    }
    return context
}

export const useVerticalSigningContext = () => {
    return [useVerticalSigningStateContext(), useVerticalSigningDispatchContext()]
}
