import { createContext } from "react"

export const initialState = {
    filter: null,
}

const initialDispatch = {}

export const VerticalSigningStateContext = createContext(initialState)
export const VerticalSigningRoadwayContext = createContext(initialDispatch)
