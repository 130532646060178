import { useCallback, useMemo } from "react"

import _ from "lodash"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { VERTICAL_SIGNING_CODE_URL_PARAM } from "../../../../common/constants/urlParams"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"
import { useAscendantCodes } from "../../hooks/utils/useAscendantCodes"
import { VerticalSigningStateContext, VerticalSigningRoadwayContext } from "./VerticalSigningContext.context"

export const VerticalSigningContextProvider = (props) => {

    const { children } = props
    const { getParam, setParam, deleteParam } = useUrlParams()
    const { data: verticalSignTypes } = useVerticalSignTypes()

    const currentFilter = getParam(VERTICAL_SIGNING_CODE_URL_PARAM)

    const ascendantCodes = useAscendantCodes(currentFilter)

    const setFilter = useCallback((newFilter) => {
        if (newFilter && currentFilter !== newFilter) {
            setParam(VERTICAL_SIGNING_CODE_URL_PARAM, newFilter)

        } else {
            deleteParam(VERTICAL_SIGNING_CODE_URL_PARAM)
        }
    }, [currentFilter, deleteParam, setParam])

    const stateValue = useMemo(() => {
        return {
            ascendantCodes: ascendantCodes,
            test: verticalSignTypes,
            filter: currentFilter,
        }
    }, [currentFilter, ascendantCodes, verticalSignTypes])

    const dispatchValue = useMemo(() => {
        return { setFilter }
    }, [setFilter])

    return (
        <VerticalSigningStateContext.Provider value={stateValue}>
            <VerticalSigningRoadwayContext.Provider value={dispatchValue}>
                {children}
            </VerticalSigningRoadwayContext.Provider>
        </VerticalSigningStateContext.Provider>
    )
}

VerticalSigningContextProvider.propTypes = {
    children: PropTypes.node,
}
