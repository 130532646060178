import {
    styled,
    List as UiList,
    Skeleton as UiSkeleton,
    Typography,
} from "@l2r-front/l2r-ui"

export const Header = styled("div")(({ theme }) => ({
    display: "flex",
    paddingBottom: theme.spacing(5),
}))

export const ErrorTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
}))

export const TitleSkeleton = styled(UiSkeleton)(({ theme }) => ({
    height: 27,
    marginBottom: theme.spacing(5),
    transform: "scale(1)",
}))

export const List = styled(UiList)(() => ({
}))