import { EMPTY_QUERY_PARAM } from "@l2r-front/l2r-query"

import { useVerticalSignTypes } from "../queries/verticalSigning/useVerticalSignTypes"

function findInCatalog(code, catalog) {
    const item = catalog.find(catalogItem => catalogItem.code === code)

    if (item) {
        return item
    } else {
        let childItem = null
        for (let i = 0; childItem === null && i < catalog.length; i++) {
            childItem = findInCatalog(code, catalog[i].children)
        }
        return childItem
    }
}

export function useAscendantCodes(code) {
    const { data: verticalSignTypes } = useVerticalSignTypes()

    const codes = []
    let codeToAdd = code
    if (verticalSignTypes) {
        while (codeToAdd && codeToAdd !== EMPTY_QUERY_PARAM) {
            codes.push(codeToAdd)
            const verticalSignType = findInCatalog(codeToAdd, verticalSignTypes)
            codeToAdd = verticalSignType?.parent
        }
    }

    return codes
}