import { useCallback } from "react"

import { useParams } from "react-router-dom"

import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { useGetVerticalSignTypeDepth } from "../../hooks/utils/useGetVerticalSignTypeDepth"
import { VerticalSignTypeList } from "../VerticalSignTypeList"
import * as Styled from "./VerticalSigningMobileContent.styled"

export const VerticalSigningMobileContent = () => {
    const { code } = useParams()
    const getVerticalSignTypeDepth = useGetVerticalSignTypeDepth()
    const navigate = useNavigateWithSearchParams()

    const handleChangeFilter = useCallback((filter) => {
        const filterCodeDepth = getVerticalSignTypeDepth(filter)
        if (filterCodeDepth % 2 === 1) {
            navigate(`./code/${filter}`)
        }
    }, [getVerticalSignTypeDepth, navigate])

    return (
        <Styled.Container>
            <VerticalSignTypeList baseVerticalSignTypeCode={code} onChangeFilter={handleChangeFilter} />
        </Styled.Container>
    )
}