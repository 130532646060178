const verticalSignTypesFR = [
    {
        "country": "FR",
        "code": "FF1",
        "name": "Panneaux de police",
        "name_long": "Panneaux de police",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "None",
        "children": [
            "CF1",
            "CF4",
            "CF2",
            "CF3",
            "CF8",
        ],
    },
    {
        "country": "FR",
        "code": "FF2",
        "name": "Panneaux directionnels",
        "name_long": "Panneaux directionnels",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "None",
        "children": [
            "CF5",
            "CF6",
        ],
    },
    {
        "country": "FR",
        "code": "FF3",
        "name": "Autres panneaux",
        "name_long": "Autres panneaux",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "None",
        "children": [
            "CF7",
        ],
    },
    {
        "country": "FR",
        "code": "CF1",
        "name": "A : Danger",
        "name_long": "A : Danger",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "FF1",
        "children": [
            "A13a",
            "A13b",
            "A15a1",
        ],
    },
    {
        "country": "FR",
        "code": "CF4",
        "name": "B : Prescription",
        "name_long": "B : Prescription",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "FF1",
        "children": [
            "B0",
        ],
    },
    {
        "country": "FR",
        "code": "CF2",
        "name": "AB : Intersections et priorités",
        "name_long": "AB : Intersections et priorités",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "FF1",
        "children": [
            "AB4",
        ],
    },
    {
        "country": "FR",
        "code": "CF3",
        "name": "C : Indication",
        "name_long": "C : Indication",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "FF1",
        "children": [
            "C107",
        ],
    },
    {
        "country": "FR",
        "code": "CF8",
        "name": "M : Panonceaux",
        "name_long": "M : Panonceaux",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "FF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A13a",
        "name": "A13a",
        "name_long": "Endroit fréquenté par les enfants",
        "description": "Endroit fréquenté par les enfants",
        "icon": "https://api.l2r.rleboeuf/media/l2r_vertical_signing/vertical_sign_type/icon_6812bf81-37dc-4f06-a853-cf583faf2363.jpeg",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A13b",
        "name": "A13b",
        "name_long": "Passage pour piéton",
        "description": "Passage pour piéton",
        "icon": "https://api.l2r.rleboeuf/media/l2r_vertical_signing/vertical_sign_type/icon_6812bf81-37dc-4f06-a853-cf583faf2363.png",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A15a1",
        "name": "A15a1",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A15a2",
        "name": "A15a2",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A15b",
        "name": "A15b",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A15c",
        "name": "A15c",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A16",
        "name": "A16",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A17",
        "name": "A17",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A18",
        "name": "A18",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A19",
        "name": "A19",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A1a",
        "name": "A1a",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A1b",
        "name": "A1b",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A1c",
        "name": "A1c",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A1d",
        "name": "A1d",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A20",
        "name": "A20",
        "name_long": "Animaux (vache)",
        "description": "Animaux (vache)",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF1",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "B0",
        "name": "B0",
        "name_long": "B0",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF4",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "AB4",
        "name": "AB4",
        "name_long": "AB4",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF2",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "C107",
        "name": "C107",
        "name_long": "C107",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF3",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "CF5",
        "name": "D : Direction",
        "name_long": "D : Direction",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "FF2",
        "children": [
            "D21a_1",
            "A21a_2",
        ],
    },
    {
        "country": "FR",
        "code": "CF6",
        "name": "E : Localisation",
        "name_long": "E : Localisation",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "FF2",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "D21a_1",
        "name": "D21a_1",
        "name_long": "D21a_1",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF5",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "A21a_2",
        "name": "A21a_2",
        "name_long": "A1a_2",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF5",
        "children": [
        ],
    },
    {
        "country": "FR",
        "code": "CF7",
        "name": "J : Balises",
        "name_long": "J : Balises",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "FF3",
        "children": [
            "J13-d_1",
        ],
    },

    {
        "country": "FR",
        "code": "J13-d_1",
        "name": "J13-d_1",
        "name_long": "J13-d_1",
        "description": "",
        "icon": "",
        "is_main": true,
        "is_textual": false,
        "parent": "CF7",
        "children": [
        ],
    },
]

export const allVerticalSignTypes = {
    "FR": verticalSignTypesFR,
}

const mapVerticalSignTypeWithChildren = (vsType, catalog) => {
    return {
        ...vsType,
        children: vsType.children.map(childCode => {
            const childType = catalog.find(type => type.code === childCode)
            return mapVerticalSignTypeWithChildren(childType, catalog)
        }),
    }
}

export const getVerticalSignTypes = (country, parent) => {
    const countryVerticalSignTypes = allVerticalSignTypes[country]
    return countryVerticalSignTypes
        .filter(vsType => vsType.parent === parent)
        .map(vsType => mapVerticalSignTypeWithChildren(vsType, countryVerticalSignTypes))
}

export const getVerticalSignType = (code) => {
    for (let catalog of Object.values(allVerticalSignTypes)) {
        const verticalSignType = catalog.find(vsType => vsType.code === code)
        if (verticalSignType) {
            return mapVerticalSignTypeWithChildren(verticalSignType, catalog)
        }
    }
    return null
}