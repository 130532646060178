import { Drawer as UiDrawer, styled } from "@l2r-front/l2r-ui"

import { ADDITIONAL_SIDEBAR_WIDTH } from "../../../../common/constants/layout"
import { VerticalSignTypeList as L2RVerticalSignTypeList } from "../VerticalSignTypeList"

export const Container = styled("div")(() => ({
    height: "100%",
    overflowY: "auto",
}))

export const VerticalSignTypeList = styled(L2RVerticalSignTypeList)(({ theme }) => ({
    padding: theme.spacing(8),
}))

export const Drawer = styled(UiDrawer)(({ index }) => ({
    "& .MuiPaper-root": {
        height: "calc(100% - 72px)",
        position: "absolute",
        left: `calc(500px + ${index * ADDITIONAL_SIDEBAR_WIDTH}px)`,
        top: 72,
        width: ADDITIONAL_SIDEBAR_WIDTH,
    },

    width: 0,
}))