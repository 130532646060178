import { useMemo } from "react"

import { useVerticalSigningStateContext } from "../../../modules/verticalSigning/contexts/VerticalSigningContext"
import { CityContentRouter } from "../../routers/CityContentRouter"
import { CityDesktopMapRouter } from "../../routers/CityDesktopMapRouter"
import { SidebarRouter } from "../../routers/SidebarRouter"
import * as Styled from "./CityDesktopLayout.styled"

export function CityDesktopLayout() {
    const { ascendantCodes } = useVerticalSigningStateContext()

    const sidebarCount = useMemo(() => {
        return Math.floor(ascendantCodes.length / 2) + 1
    }, [ascendantCodes])

    return (
        <Styled.Wrapper>
            <SidebarRouter />
            <CityContentRouter />
            <Styled.Map sidebarCount={sidebarCount}>
                <CityDesktopMapRouter />
            </Styled.Map>
        </Styled.Wrapper>
    )
}
