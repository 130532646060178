import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { Sidebar as L2RSidebar, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { VerticalSigningSidebar } from "../../containers/VerticalSigningSidebar"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"

export function VerticalSigningSidebarRouter() {
    const { ascendantCodes } = useVerticalSigningStateContext()
    const isMobile = useIsMobileDevice()

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <VerticalSigningSidebar /> },
    ]), [])

    return <L2RSidebar shadowed={!isMobile && ascendantCodes.length >= 2}>{useRoutes(sidebarRoutes)}</L2RSidebar>
}
