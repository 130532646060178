import { useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import {
    VerticalSignTypeList as VerticalSignTypeListComponent,
    VerticalSignTypeListError,
    VerticalSignTypeListSkeleton,
} from "../../components/VerticalSignTypeList"
import { useVerticalSigningContext } from "../../contexts/VerticalSigningContext"
import { useVerticalSignType, useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"

export function VerticalSignTypeList(props) {

    const {
        baseVerticalSignTypeCode,
        className,
        onChangeFilter,
    } = props

    const { t } = useTranslation()
    const { data } = useVerticalSignType(baseVerticalSignTypeCode)
    const [state, dispatch] = useVerticalSigningContext()
    const { ascendantCodes, filter } = state

    const {
        data: verticalSignTypes,
        isError: isErrorVerticalSignTypes,
        isLoading: isLoadingVerticalSignTypes,
    } = useVerticalSignTypes(baseVerticalSignTypeCode)

    const { setFilter } = dispatch

    const title = data?.name ?? t(I18N_NAMESPACE, "containers.verticalSignTypeList.defaultTitle")

    const applyFilter = useCallback((newFilter) => {
        if (filter === newFilter || ascendantCodes.indexOf(newFilter) >= 0) {
            const filterIndex = ascendantCodes.indexOf(newFilter)
            if (filterIndex !== ascendantCodes.length - 1) {
                setFilter(ascendantCodes[filterIndex + 1])
            } else {
                setFilter(null)
            }
        } else {
            setFilter(newFilter)
            onChangeFilter?.(newFilter)
        }
    }, [filter, ascendantCodes, setFilter, onChangeFilter])

    if (isErrorVerticalSignTypes) {
        return <VerticalSignTypeListError className={className} />
    }

    if (isLoadingVerticalSignTypes) {
        return <VerticalSignTypeListSkeleton className={className} />
    }

    return <VerticalSignTypeListComponent
        onClick={applyFilter}
        baseVerticalSignTypeCode={baseVerticalSignTypeCode}
        ascendantCodes={ascendantCodes}
        className={className}
        data={verticalSignTypes}
        filter={filter}
        title={title}
    />
}

VerticalSignTypeList.propTypes = {
    baseVerticalSignTypeCode: PropTypes.string,
    className: PropTypes.string,
    onChangeFilter: PropTypes.func,
}